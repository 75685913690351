import React from "react";

//context that store information about if user complete main survey in past or its first time
const SurveyContext = React.createContext({
	selectedSurveyId: null,
	surveyCompletedInPast: false,
	surveyList: []
});

export default SurveyContext;

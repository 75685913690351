import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import logo from "../../assets/img/logo.svg";
import appTexts from "../../data/appTexts";
import FormGroup from "../form/FormGroup";
import {healthInfoQuestions} from "../../data/healthInfoQuestions";
import FormChoice from "../form/FormChoice";
import Button from "../Button";
import {values} from "ramda";

const propTypes = {
	formValues: PropTypes.object,
	updateFormData: PropTypes.func,
	onSubmit: PropTypes.func,
};

const defaultProps = {};

const HealthInfoView = (props) => {
	const {formValues, updateFormData, onSubmit} = props;

	//check if form has any checked value
	const isSaveButtonDisabled = useMemo(() => {
		// console.log(formValues, values(formValues))
		return !values(formValues).some(formValue => formValue === 1)
	}, [formValues])

	return (
		<>
			<section className="screen">
				<div className="container container-custom">
					<div>
						<img src={logo} className="logo-top" alt=""/>
					</div>

					<div>
						<h1 className="section-title">{appTexts.healthInfo.formTitleText}</h1>

						<form
							onSubmit={onSubmit}
						>
							<h4 className="mb-3">{appTexts.healthInfo.formFieldDiseaseLabel}</h4>
							<FormGroup>
								{healthInfoQuestions.map(question => {
									const isChecked = !!formValues[question.name]
									const eventAfterChange = () => updateFormData(question)

									return (

										<FormChoice
											type={"checkbox"}
											name={question.name}
											label={question.label}
											checked={isChecked}
											onChange={eventAfterChange}
										/>
									)
								})}
							</FormGroup>

							<p>
								<Button
									type={"submit"}
									className="btn-block"
									name="health_info_submit"
									disabled={isSaveButtonDisabled}
								>
									{appTexts.healthInfo.formSubmitText}
								</Button>
							</p>
						</form>
					</div>

					<div/>
				</div>
			</section>
		</>
	);
};

HealthInfoView.propTypes = propTypes;
HealthInfoView.defaultProps = defaultProps;

export default HealthInfoView;

import React from 'react';
import PropTypes from 'prop-types';
import appTexts from "../data/appTexts";
import Button from "./Button";

const propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
};

const defaultProps = {};

const EndSessionButton = (props) => {
	const {className, disabled} = props;

	const endSession = () => {
		window.location.reload()
	}

	return (
		<>
			<Button
				name="button_end_session"
				color="btn-custom-secondary"
				className={className}
				disabled={disabled}
				onClick={endSession}
			>
				{appTexts.surveyFinished.endSessionButtonText}
			</Button>
		</>
	);
};

EndSessionButton.propTypes = propTypes;
EndSessionButton.defaultProps = defaultProps;

export default EndSessionButton;

import axios from "axios";
import {API_PREFIX} from "../data/config";

//template request to wim
const _request = async (endpoint, data) => {
	return await axios.post(API_PREFIX + endpoint, data);
};

export const apiPathLoginUser = 'LoginByPhone'
export const requestLoginUser = async (phone, userCode) => {
	const data = {
		Phone: phone,
		UserCode: userCode,
	};

	return await _request(apiPathLoginUser, data);
}

export const apiPathIsSurveyComplete = 'GetLastMKSDate'
export const requestIsSurveyComplete = async () => {
	return await _request(apiPathIsSurveyComplete, null);
}

export const apiPathUserDetails = 'GetOsobaPersonaliaByToken'
export const requestUserDetails = async () => {
	return await _request(apiPathUserDetails, null);
}

//send main survey answers data with optional GPS location
export const apiPathSendSurvey = 'SendResult';
export const apiPathSendPostCovidSurvey = 'SendResultPostCOVID';
export const requestSendSurvey = async (answersData, messageType, geoPosition) => {
	const data = {
		MessageType: messageType,
		Params: answersData,
		GeoLat: '',
		GeoLon: '',
	};

	let apiPath = apiPathSendSurvey;

	if (messageType === 4) {
		apiPath = apiPathSendPostCovidSurvey;
	}

	if (geoPosition) {
		data.GeoLat = geoPosition.gpsLat.toFixed(4)
		data.GeoLon = geoPosition.gpsLng.toFixed(4)
	}

	return await _request(apiPath, data);
}

//get user personal details
export const apiPathPersonalData = 'SaveOrUpdatePatientByToken'
export const requestPersonalData = async (formData) => {
	return await _request(apiPathPersonalData, formData);
}

export const apiPathGetUserEmail = 'GetEmailFromToken'
export const requestGetUserEmail = async () => {
	return await _request(apiPathGetUserEmail)
}

export const apiPathSetUserEmail = 'UpdatePersonEmail'
export const requestSetUserEmail = async (userEmail) => {
	const data = {
		Email: userEmail
	}
	return await _request(apiPathSetUserEmail, data)
}

export const apiPathSendQuestion = 'SendPersonQuestion'
export const requestSendQuestion = async (question) => {
	const data = {
		Pytanie: question,
	}

	return await _request(apiPathSendQuestion, data)
}
//
// export const requestAskQuestion = async (formData) => {
// 	const contactApiUrl = 'https://sygmo-api.gcbtl.pl/sapi/modules/contact/form/';
// 	const hash = '8a3727102c788d84a91c7b48bb450ee8';
//
// 	return await axios.post(contactApiUrl + hash, formData);
// };

export const apiPathGetApplicationList = 'GetApplicationList';
export const requestGetSurveyList = async () => {
	return await _request(apiPathGetApplicationList);
}

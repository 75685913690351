import React, {useEffect, useState} from 'react'
import {TokenContext} from "../contexts"
import {storageTokenKey} from "../../data/config"
import {isAppHaveUpdate, isDev, setTokenToAllUpcomingRequests} from "../../helpers/functions";
import appTexts from "../../data/appTexts";
import {requestLoginUser} from "../../helpers/requests";

const TokenContextContainer = (props) => {
	const [token, setToken] = useState('')

	//when app starts, check if is token in sessionStorage and set it in our app
	useEffect(() => {
		const tokenFromStorage = sessionStorage.getItem(storageTokenKey)
		isDev && console.log('make sure that its only called once')
		if (tokenFromStorage) {
			loginUserByToken(tokenFromStorage)
			sessionStorage.removeItem(storageTokenKey)
		}
	}, [])

	const logoutUser = () => {
		setToken('')
	}

	const loginUserByToken = (token) => {
		if (token) {
			//do it firstly!
			setTokenToAllUpcomingRequests(token)

			setToken(token)
		}
	}

	const loginUser = async (userPhone, userCode) => {
		let token;

		try {
			const responseLogin = await requestLoginUser(userPhone, userCode);

			token = responseLogin.data?.value;

		} catch (e) {
			throw appTexts.login.errors.wrongData
		}

		//when token is null or ""
		if (!token) throw appTexts.login.errors.responseTokenError

		loginUserByToken(token);

		//update app when new service worker is waiting
		if (isAppHaveUpdate()) {
			//save token and userData in session storage to read it after reload
			sessionStorage.setItem(storageTokenKey, token);

			//reload page to run new service worker
			window.location.reload();
		}
	}

	return (
		<>
			<TokenContext.Provider value={{
				token,
				loginUser,
				logoutUser,
			}}>
				{props.children}
			</TokenContext.Provider>
		</>
	)
}

export default TokenContextContainer

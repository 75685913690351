import React, {useContext} from "react";
import PropTypes from "prop-types";
import appTexts from "../../../data/appTexts";
import Button from "../../Button";
import {TokenContext} from "../../contexts";
import logo_small from "../../../assets/img/logo_small.svg";
import sygmo_covid from '../../../assets/img/sygmo_covid.svg';
import sygmo_covid_brand from '../../../assets/img/sygmo_covid_brand.svg';
import sygmo_postcovid from '../../../assets/img/sygmo_postcovid.svg';
import sygmo_postcovid_brand from '../../../assets/img/sygmo_postcovid_brand.svg';

const propTypes = {
	surveyList: PropTypes.array,
	canShowList: PropTypes.bool,
	setSurveyId: PropTypes.func,
};

const defaultProps = {};

const SurveyListView = (props) => {
	const { surveyList, canShowList, setSurveyId } = props;
	const {logoutUser} = useContext(TokenContext)

	if (!canShowList) return null;

	const getSurveyIdLogo = (app) => {
		switch (app.id) {
			case 1: {
				return (
					<>
						<img src={sygmo_covid} className="survey-list-logo-top" />
						<br/>
						<img src={sygmo_covid_brand} className="survey-list-logo-bottom" />
					</>
				)
			}
			case 4: {
				return (
					<>
						<img src={sygmo_postcovid} className="survey-list-logo-top" />
						<br/>
						<img src={sygmo_postcovid_brand} className="survey-list-logo-bottom" />
					</>
				)
			}
			default: {
				return (app.name)
			}
		}
	}

	return (
		<section className="screen">
			<div className="container container-custom">
				<div className="box box-light">{appTexts.surveyList.chooseSurvey}</div>

				<div className="row py-3">
					{surveyList.map(app =>
						<div key={app.id} className="col-12 col-md text-center mb-3">
							<span className="survey-list-item" onClick={() => setSurveyId(app.id)}>
								{getSurveyIdLogo(app)}
							</span>
						</div>
					)}
				</div>

				<div>
					<p>
						<Button
							className="btn-block"
							onClick={logoutUser}
						>
							{appTexts.surveyList.logout}
						</Button>
					</p>
					<p className="text-center">
						<img src={logo_small} alt="" className="img-fluid logo-small"/>
					</p>
				</div>
			</div>
		</section>
	)
}

SurveyListView.propTypes = propTypes;
SurveyListView.defaultProps = defaultProps;

export default SurveyListView;

import React from "react";

//context that store user token and actions to login or logout user
const TokenContext = React.createContext({
	token: '',
	loginUserByToken: () => {
	},
	logoutUser: () => {
	},
});

export default TokenContext;

import React, {useContext} from "react";
import {SurveyContext} from "../contexts";
import SurveySentPostCovidView from "../views/survey/SurveySentPostCovidView";
import SurveySentCovidView from "../views/survey/SurveySentCovidView";

const SurveySentContainer = () => {
	const {selectedSurveyId} = useContext(SurveyContext);

	switch (selectedSurveyId) {
		case 4: {
			return <SurveySentPostCovidView/>
		}
		case 1:
		default: {
			return <SurveySentCovidView/>
		}
	}
}

export default SurveySentContainer;

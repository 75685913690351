import React from 'react'
import HealthInfoContainer from "../components/containers/HealthInfoContainer";

const HealthInfoScreen = () => {
	return (
		<HealthInfoContainer/>
	)
}

export default HealthInfoScreen

import React from 'react';

const propTypes = {};

const defaultProps = {};

const FormLabel = (props) => {
	return (
		<>
			<label>{props.children}</label>
		</>
	);
};

FormLabel.propTypes = propTypes;
FormLabel.defaultProps = defaultProps;

export default FormLabel;

import React from 'react'
import PropTypes from 'prop-types'
import classNames from "classnames"
import SurveyQuestionBtnFinish from "./SurveyQuestionBtnFinish"
import SurveyQuestionBtnNext from "./SurveyQuestionBtnNext"
import SurveyQuestionBtnPrev from "./SurveyQuestionBtnPrev"
import {isNil} from "ramda"

const propTypes = {
	selectedQuestion: PropTypes.number,
	questions: PropTypes.array,
	answers: PropTypes.object,
	isFormSubmitting: PropTypes.bool,
	prevQuestion: PropTypes.func,
	nextQuestion: PropTypes.func,
	finishSurvey: PropTypes.func,
}

const defaultProps = {}

const SurveyNavigation = (props) => {
	const {selectedQuestion, questions, answers, isFormSubmitting, prevQuestion, nextQuestion, finishSurvey} = props

	const isPrevQuestionBtnDisabled = selectedQuestion === 0 || isFormSubmitting

	const isRequiredQuestionFilledFunction = () => {
		const currentQuestion = questions[selectedQuestion]

		return (
			//if question has required key and user still not fill answer
			currentQuestion?.required &&
			isNil(answers[currentQuestion?.key])
		)
	}

	const isRequiredQuestionFilled = isRequiredQuestionFilledFunction()

	const isNextQuestionBtnDisabled = isRequiredQuestionFilled

	//if user is on last question
	const isBtnFinishVisible = selectedQuestion === questions.length - 1
	const isBtnFinishDisabled = isFormSubmitting || isRequiredQuestionFilled

	//TODO: show finish button on last question

	return (
		<>
			<div className="questionnaire__navigation">
				<div className="form-row align-items-center justify-content-sm-between justify-content-center">
					<div className="col-auto">
						<SurveyQuestionBtnPrev
							isDisabled={isPrevQuestionBtnDisabled}
							onClick={prevQuestion}
						/>
					</div>
					<div className="col align-self-center">
						<div className="questionnaire__step__list">
							{questions.map((question, questionsIndex) => {
								const isActive = selectedQuestion === questionsIndex

								return (
									<div key={questionsIndex} className={classNames('questionnaire__step__item', {'active': isActive})}/>
								)
							})}
						</div>
					</div>
					<div className="col-auto">

						<SurveyQuestionBtnNext
							isDisabled={isNextQuestionBtnDisabled}
							isHidden={isBtnFinishVisible}
							onClick={nextQuestion}
						/>

						<SurveyQuestionBtnFinish
							isDisabled={isBtnFinishDisabled}
							isHidden={!isBtnFinishVisible}
							onClick={finishSurvey}
						/>

					</div>
				</div>
			</div>
		</>
	)
}

SurveyNavigation.propTypes = propTypes
SurveyNavigation.defaultProps = defaultProps

export default SurveyNavigation

import React, {useContext, useEffect, useState} from 'react';
import {SurveyContext, TokenContext} from "../contexts";
import {requestIsSurveyComplete} from "../../helpers/requests";
import {isDev} from "../../helpers/functions";
import {isNil} from "ramda";

const SurveyContextContainer = (props) => {
	const [surveyCompletedInPast, setSurveyCompletedInPast] = useState(false)
	const [selectedSurveyId, setSelectedSurveyId] = useState(null);
	const [surveyList, setSurveyList] = useState([]);
	const {token} = useContext(TokenContext)

	useEffect(() => {
		if (!token) return

		isDev && console.log(token)
		//do request for user last survey completed status
		checkIfUserCompletedSurveyInPast()
	}, [token])

	const checkIfUserCompletedSurveyInPast = async () => {
		try {
			const responseFirstTime = await requestIsSurveyComplete();

			/**
			 * "0" - first time
			 * "1" - another time
			 */

			if (responseFirstTime.data?.value === "1") {
				setSurveyCompletedInPast(true);
			}
		} catch (e) {
			isDev && console.log('Błąd podczas pobierania ostatniego wpisu', e);
		}
	}

	return (
		<>
			<SurveyContext.Provider value={{
				surveyCompletedInPast,
				selectedSurveyId,
				setSelectedSurveyId,
				surveyList,
				setSurveyList
			}}>
				{props.children}
			</SurveyContext.Provider>
		</>
	);
};

export default SurveyContextContainer;

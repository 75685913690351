import React from 'react';
import PropTypes from 'prop-types';
import {
	surveyQuestionTypeCheckbox,
	surveyQuestionTypeRange,
	surveyQuestionTypeRadio,
	surveyQuestionTypeTextarea, surveyQuestionTypePercent
} from "../../../data/surveyQuestions";
import {FormChoice, FormFieldHint, FormTextarea} from "../../form";
import FormRange from "../../form/FormRange";

const propTypes = {
	question: PropTypes.object,
	answers: PropTypes.object,
	selectAnswer: PropTypes.func,
};

const defaultProps = {};

const SurveyQuestionAnswers = (props) => {
	const {question, answers, selectAnswer} = props;

	const renderAnswerType = () => {
		const questionKey = question.key;
		let answerValue = answers[questionKey]

		switch (question.type) {
			case surveyQuestionTypeRadio: {
				return (
					<>
						{question.answers.map((answer, answerIndex) => {
								const isChecked = answerValue === answerIndex;
								const onChange = () => selectAnswer(questionKey, answerIndex);

								return (
									<FormChoice
										key={questionKey + '_' + answerIndex}
										type={"radio"}
										label={answer.label}
										checked={isChecked}
										name={questionKey}
										classNamePrefix="questionnaire__answers__item"
										onChange={onChange}
									/>)
							}
						)}
						<FormFieldHint
							hint={question.hint}
						/>
					</>
				)
			}
			case surveyQuestionTypeCheckbox: {
				return (
					<>
						{question.answers.map((answer, answerIndex) => {
								const answerKey = answer.key;
								answerValue = answers[answerKey]

								const isChecked = answerValue === 1;
								const valueOnChange = isChecked ? 0 : 1;
								const onChange = () => selectAnswer(answerKey, valueOnChange)

								return (
									<FormChoice
										key={questionKey + '_' + answerKey}
										type={"checkbox"}
										label={answer.label}
										name={answerKey}
										checked={isChecked}
										classNamePrefix="questionnaire__answers__item"
										onChange={onChange}
									/>
								);
							}
						)}
						<FormFieldHint
							hint={question.hint}
						/>
					</>
				)
			}
			case surveyQuestionTypeTextarea: {
				const onChange = (event) => selectAnswer(questionKey, event.target.value)

				return (
					<>
						<FormTextarea
							value={answerValue}
							onChange={onChange}
						/>
						<FormFieldHint
							hint={question.hint}
						/>
					</>
				)
			}
			case surveyQuestionTypeRange:
			case surveyQuestionTypePercent:
				{
				const onChange = (event) => selectAnswer(questionKey, event.target.value)

				return (
					<>
						<FormRange
							min={question.rangeMin}
							max={question.rangeMax}
							minLabel={question.minLabel}
							maxLabel={question.maxLabel}
							value={answerValue}
							onChange={onChange}
							renderValue={value => {
								if (question.type === surveyQuestionTypePercent) {
									return `${value}%`;
								}

								return value;
							}}
						/>
						<FormFieldHint
							hint={question.hint}
						/>
					</>
				)
			}
			default:
				return null;
		}
	}

	return (
		<>
			{renderAnswerType()}
		</>
	);
};

SurveyQuestionAnswers.propTypes = propTypes;
SurveyQuestionAnswers.defaultProps = defaultProps;

export default SurveyQuestionAnswers;

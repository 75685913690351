import React, {useContext, useState} from 'react';
import SurveyContext from "../contexts/SurveyContext";
import {useSurveyData} from "../../hooks/useSurveyData";
import SurveyView from "../views/survey/SurveyView";
import {keys} from "ramda";
import {requestSendSurvey} from "../../helpers/requests";
import TokenContext from "../contexts/TokenContext";
import {getCurrentGeoPosition} from "../../helpers/geo_position";
import appTexts from "../../data/appTexts";
import SurveySentContainer from "./SurveySentContainer";
import {isDev} from "../../helpers/functions";

const SurveyContainer = () => {
	//use this to show different view after survey finished
	const [surveySent, setSurveySent] = useState(false);
	//need this variable for set disabled on prev question button and survey finished
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);

	const [error, setError] = useState('')

	const {token} = useContext(TokenContext);
	const {selectedSurveyId} = useContext(SurveyContext);

	//custom hook to manage survey
	const {
		selectedQuestion,
		questions,
		answers,
		selectAnswer,
		prevQuestion,
		nextQuestion,
	} = useSurveyData();

	const finishSurvey = async () => {

		setIsFormSubmitting(true);
		setError('');

		//prepare data to send it by API
		const answersFormData = [];

		if (selectedSurveyId === 1) answersFormData.push({12: "0"});

		keys(answers).forEach(questionKey => {
			answersFormData.push({[questionKey]: answers[questionKey].toString()});
		});


		//get GPS location and add it to request. if something goes wrong omit this value
		let geoPosition;
		try {
			geoPosition = await getCurrentGeoPosition();
		} catch (error) {
			 console.warn(error);
		}

		//send questionnaire data to API.
		try {
			const response = await requestSendSurvey(answersFormData, selectedSurveyId, geoPosition, token);

			if (response.data?.value === 'Data Error') {
				setError(appTexts.survey.errors.apiError)
				return
			}

			setError('')
			setSurveySent(true);
			setIsFormSubmitting(false);
		} catch (e) {
			console.log(e);
			//show errors and and remove disbaled form buttons
			setError(appTexts.survey.errors.apiError)
			setIsFormSubmitting(false)
		}
	}

	if (surveySent) return (
		<SurveySentContainer/>
	)

	return (
		<>
			<SurveyView
				selectedQuestion={selectedQuestion}
				questions={questions}
				answers={answers}
				isFormSubmitting={isFormSubmitting}
				selectAnswer={selectAnswer}
				prevQuestion={prevQuestion}
				nextQuestion={nextQuestion}
				finishSurvey={finishSurvey}
				error={error}
			/>
		</>
	);
};

export default SurveyContainer;

import React, {useContext, useEffect, useRef, useState} from 'react';
import TokenContext from "../contexts/TokenContext";
import {useHistory} from "react-router-dom";
import {healthInfoQuestions} from "../../data/healthInfoQuestions";
import {assoc} from "ramda";
import {requestPersonalData} from "../../helpers/requests";
import {PATH_SURVEYS_LIST, PATH_SURVEY} from "../../data/routes";
import HealthInfoView from "../views/HealthInfoView";

const HealthInfoContainer = () => {
	const [formValues, setFormValues] = useState({})
	//variable that store questions that have resetOthers key in config
	const questionsResetOthers = useRef([])
	const {logoutUser} = useContext(TokenContext)
	const history = useHistory()

	//set healthInfoQuestions initial values to formValues
	useEffect(() => {
		const initialFormValues = {}

		questionsResetOthers.current = healthInfoQuestions.filter(question => question.resetOthers)

		healthInfoQuestions.forEach(question => {
			initialFormValues[question.name] = question.initialValue
		})

		setFormValues(initialFormValues)
	}, [])

	const updateFormData = (question) => {
		setFormValues(formValues => {
			const updatedValue = !!formValues[question.name] ? 0 : 1

			let updatedFormValues = assoc(question.name, updatedValue, formValues)

			//check if question has resetOthers attr and reset other questions with resetOthers key or all questions with resetOthers key
			for (const questionResetOthers of questionsResetOthers.current) {
				if (questionResetOthers.name !== question.name) {
					updatedFormValues[questionResetOthers.name] = 0
				}
			}

			//change value to 0 of all defined checkbox if question has resetOthers key
			if (question.resetOthers) {
				//iterate over all values to reset value
				for (const formFieldName in formValues) {
					if (formFieldName !== question.name) {
						updatedFormValues[formFieldName] = 0
					}
				}
			}

			return updatedFormValues
		})
	}

	const onSubmit = async (event) => {
		event.preventDefault()

		try {
			const requestBody = {
				"ApplicationId": 1,
				"Instytucja": 24,
				...formValues,
			}

			await requestPersonalData(requestBody)

			history.push(PATH_SURVEY)
		} catch (e) {
			if (e.response.status === 401) {
				logoutUser()
			}
		}
	}

	return (
		<>
			<HealthInfoView
				formValues={formValues}
				updateFormData={updateFormData}
				onSubmit={onSubmit}
			/>
		</>
	);
};

export default HealthInfoContainer;

import React from 'react';
import SurveyContainer from "../components/containers/SurveyContainer";

const SurveyScreen = () => {
	return (
		<SurveyContainer/>
	);
};

export default SurveyScreen;

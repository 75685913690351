import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.scss';
import {storageUpdateAvailableKey, storageUpdateAvailableValue} from "./data/config";

//remove key from storage that give you information about is update availability
sessionStorage.removeItem(storageUpdateAvailableKey);

ReactDOM.render(
	<React.StrictMode>
		<App/>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register({
	onUpdate: registration => {
		const waitingServiceWorker = registration.waiting;

		if (waitingServiceWorker) {
			waitingServiceWorker.addEventListener("statechange", event => {
				if (event.target.state === "activated") {
					//set sessionStorage key when update is available
					sessionStorage.setItem(storageUpdateAvailableKey, storageUpdateAvailableValue);
				}
			});

			waitingServiceWorker.postMessage({type: "SKIP_WAITING"});
		}
	}
});

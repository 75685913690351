import React from 'react';

const propTypes = {};

const defaultProps = {};

const FormGroup = (props) => {
	const {children} = props;

	return (
		<>
			<div className="form-group">
				{children}
			</div>
		</>
	);
};

FormGroup.propTypes = propTypes;
FormGroup.defaultProps = defaultProps;

export default FormGroup;

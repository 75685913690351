import React from 'react';
import AskQuestionContainer from "../components/containers/AskQuestionContainer";

const AskQuestionScreen = () => {
	return (
		<AskQuestionContainer/>
	);
};

export default AskQuestionScreen;

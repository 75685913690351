import React from 'react';
import PropTypes from 'prop-types';
import logo from "../../assets/img/logo.svg";
import enterna from '../../assets/img/enterna.svg'
import appTexts from "../../data/appTexts";
import FormGroup from "../form/FormGroup";
import FormInput from "../form/FormInput";
import ErrorMessage from "../ErrorMessage";
import LoginScreenFooter from "../LoginScreenFooter";
import Button from "../Button";
import FormChoice from "../form/FormChoice";

const propTypes = {
	error: PropTypes.string,
	userPhone: PropTypes.string,
	userCode: PropTypes.string,
	regulations: PropTypes.bool,
	onUserPhoneChange: PropTypes.func,
	onUserCodeChange: PropTypes.func,
	onRegulationsChange: PropTypes.func,
	onSubmit: PropTypes.func,
};

const defaultProps = {};

const LoginView = (props) => {
	const {error, userPhone, userCode, regulations, onUserPhoneChange, onUserCodeChange, onRegulationsChange, onSubmit} = props;

	return (
		<>
			<section className="screen">
				<div className="container container-custom">
					<div className="d-flex justify-content-between">
						<img src={logo} className="logo-top" alt=""/>
						<a href="https://enterna-medica.pl/" target="_blank" rel="noopener noreferrer">
							<img src={enterna} className="logo-top" alt=""/>
						</a>
					</div>

					<div>
						<h1 className="section-title">{appTexts.login.formTitleText}</h1>

						<form onSubmit={onSubmit}>
							<FormGroup>
								<FormInput
									value={userPhone}
									name={"user_phone"}
									placeholder={appTexts.login.fieldPhonePlaceholder}
									onChange={onUserPhoneChange}
								/>
							</FormGroup>
							<FormGroup>
								<FormInput
									value={userCode}
									type={"password"}
									name={"user_code"}
									placeholder={appTexts.login.fieldUserCodePlaceholder}
									onChange={onUserCodeChange}
								/>
							</FormGroup>
							<FormGroup>
								<FormChoice
									type={"checkbox"}
									name={"regulations"}
									checked={regulations}
									onChange={onRegulationsChange}
								>
									Akceptuję <a href="regulamin.pdf" target="_blank" rel="noopener noreferrer">regulamin</a>
								</FormChoice>
							</FormGroup>

							<p>
								<Button
									name={"login_submit"}
									className={"btn-block"}
									type={"submit"}
								>
									{appTexts.login.formSubmitText}
								</Button>
							</p>

							<ErrorMessage
								errorMessage={error}
							/>

							<p><a href="http://enterna-medica.pl/uslugi_medyczne/sygmo/pracownik" target="_blank"  rel="noopener noreferrer">Problem
								z logowaniem?</a></p>
						</form>
					</div>

					<div>
						<LoginScreenFooter/>
					</div>
				</div>
			</section>
		</>
	);
};

LoginView.propTypes = propTypes;
LoginView.defaultProps = defaultProps;

export default LoginView;

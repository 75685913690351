import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	value: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
};

const defaultProps = {};

const FormTextarea = (props) => {
	const {value, name, onChange} = props;

	return (
		<>
			<textarea
				rows={10}
				className="form-control form-control-custom"
				value={value}
				name={name}
				onChange={onChange}
			/>
		</>
	);
};

FormTextarea.propTypes = propTypes;
FormTextarea.defaultProps = defaultProps;

export default FormTextarea;

import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../Button";

const propTypes = {
	isDisabled: PropTypes.bool,
	isHidden: PropTypes.bool,
	onClick: PropTypes.func,
};

const defaultProps = {};

const SurveyQuestionBtnNext = (props) => {
	const {isDisabled, isHidden, onClick} = props;

	return (
		<>
			<Button
				className={"btn-chevron"}
				disabled={isDisabled}
				hidden={isHidden}
				name="survey_next"
				onClick={onClick}
			>
				<i className="icon icon-chevron-right"/>
			</Button>
		</>
	);
};

SurveyQuestionBtnNext.propTypes = propTypes;
SurveyQuestionBtnNext.defaultProps = defaultProps;

export default SurveyQuestionBtnNext;

import React from 'react';
//import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {isEmpty, isNil} from "ramda";

const propTypes = {
	type: PropTypes.oneOf(["radio", "checkbox"]),
	name: PropTypes.string,
	label: PropTypes.string,
	classNamePrefix: PropTypes.string,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
};

const defaultProps = {};

const FormChoice = (props) => {
	const {type, label, name, classNamePrefix, checked, children, onChange} = props;

	const is_class_name_prefix_empty = isEmpty(classNamePrefix) || isNil(classNamePrefix);

	return (
		<>
			<div
				className={classNames({[classNamePrefix]: !is_class_name_prefix_empty})}
			>
				<label
					className={classNames(`custom-control custom-${type}`, {
						[`${classNamePrefix}__choice`]: !is_class_name_prefix_empty,
					})}
				>
					<input
						type={type}
						name={name}
						className={classNames('custom-control-input', {[`${classNamePrefix}__choice__field`]: !is_class_name_prefix_empty})}
						checked={checked}
						onChange={onChange}
					/>
					<span
						className={classNames('custom-control-label', {[`${classNamePrefix}__choice__name`]: !is_class_name_prefix_empty})}
					>
						{label || children}
					</span>
				</label>
			</div>
		</>
	);
};

FormChoice.propTypes = propTypes;
FormChoice.defaultProps = defaultProps;

//const mapStateToProps = state => {};

//const actionCreators = {};

//export default connect(mapStateToProps, actionCreators)(FormChoice);
export default FormChoice;

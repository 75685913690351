import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	hint: PropTypes.string,
};

const defaultProps = {};

const FormFieldHint = (props) => {
	const {hint} = props;

	if (!hint) return null

	return (
		<>
			<small className="form-text text-muted question-hint">
				{hint}
			</small>
		</>
	);
};

FormFieldHint.propTypes = propTypes;
FormFieldHint.defaultProps = defaultProps;

export default FormFieldHint;

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	type: PropTypes.oneOf(['text', 'password']),
	value: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
};

const defaultProps = {
	type: 'text',
	value: '',
	className: 'form-control form-control-custom',
};

const FormInput = (props) => {
	const {type, value, name, className, placeholder, onChange} = props;

	return (
		<>
			<input
				type={type}
				value={value}
				name={name}
				className={className}
				placeholder={placeholder}
				onChange={onChange}
			/>
		</>
	);
};

FormInput.propTypes = propTypes;
FormInput.defaultProps = defaultProps;

export default FormInput;

import React from 'react';
import enterna from "../../../assets/img/enterna.svg";
import {Link} from "react-router-dom";
import {PATH_ASK_QUESTIONS} from "../../../data/routes";
import logo_small from "../../../assets/img/logo_small.svg";
import appTexts from "../../../data/appTexts";
import EndSessionButton from "../../EndSessionButton";

const SurveySentCovidView = () => {

	return (
		<>
			<section className="screen">
				<div className="container container-custom">
					<div className="mb-5">
						<img src={enterna} className="logo-enterna img-fluid" alt=""/>
					</div>
					<div className="text-center">
						<div className="mb-3">
							<h2 className="text-custom-primary mb-0">
								Dziękujemy
							</h2>
							<h4 className="text-custom-primary">
								za wypełnienie ankiety samokontroli.
							</h4>
						</div>
						<p>
							Wprowadzone dane zostały wysłane do
							centrum telemonitoringu. Na ich podstawie
							nasi konsultanci ocenią prawdopodobieństwo
							zakażenia koronawirusem. Brak kontaktu
							ze strony służb medycznych oznacza brak
							konieczności dalszej pilnej diagnostyki.

						</p>
						<p>
							UWAGA: zakażenie może przebiegać
							bezobjawowo. Pewność dają tylko
							przeprowadzone testy.
						</p>
						<h5 className="text-custom-primary mb-3">
							W przypadku zaostrzenia objawów
							sugerujemy pilny kontakt z lekarzem
							rodzinnym, lekarzem konsultującym
							/dyżurnym najbliższego Oddziału
							Obserwacyjno-Zakaźnego
							lub odpowiednią dla miejsca zamieszkania
							Stacją Sanitarno-Epidemiologiczną.
						</h5>
						<p>
							Nasi konsultanci medyczni są do Pani/Pana dyspozycji w godzinach 16.00-20.00 od poniedziałku
							do piątku.
							<br/>
							Można zadawać pytania za pomocą formularza.
						</p>
						<p>
							<Link to={PATH_ASK_QUESTIONS} className="btn btn-custom btn-custom-secondary">
								{appTexts.surveyFinished.askQuestionButtonText}
							</Link>
						</p>
						<p>
							<EndSessionButton/>
						</p>
					</div>
					<p className="text-center">
						<img src={logo_small} alt="" className="img-fluid logo-small"/>
					</p>
				</div>
			</section>
		</>
	);
};

export default SurveySentCovidView;

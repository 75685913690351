import React from 'react';
import Routing from "./components/Routing";
import ContextsContainer from "./components/containers/ContextsContainer";

const App = () => {
	return (
		<>
			<ContextsContainer>
				<Routing/>
			</ContextsContainer>
		</>
	);
}

export default App;

import React from 'react'
import LoginContainer from "../components/containers/LoginContainer"

const LoginScreen = () => {
	return (
		<>
			<LoginContainer/>
		</>
	)
}

export default LoginScreen

import React, {useContext, useState} from 'react';
import TokenContext from "../contexts/TokenContext";
import {isEmptyOrHasWhitespaces} from "../../helpers/functions";
import appTexts from "../../data/appTexts";
import LoginView from "../views/LoginView";

const LoginContainer = () => {
	const [userPhone, setUserPhone] = useState('')
	const [userCode, setUserCode] = useState('')
	const [regulations, setRegulations] = useState(false)
	const [error, setError] = useState('')

	const {loginUser} = useContext(TokenContext)

	const onUserPhoneChange = (event) => {
		setUserPhone(event.target.value)
	}

	const onUserCodeChange = (event) => {
		setUserCode(event.target.value)
	}

	const onRegulationsChange = () => {
		setRegulations(regulations => !regulations)
	}

	const onSubmit = async (event) => {
		event.preventDefault()
		setError('')

		//check if fields are empty or has whitespace characters
		if (
			isEmptyOrHasWhitespaces(userPhone) ||
			isEmptyOrHasWhitespaces(userCode) ||
			!regulations
		) {
			setError(appTexts.login.errors.emptyFields)
			return
		}

		//login request and set token, later get email address on UserDataContextContainer in hook
		try {
			const userPhone2 = userPhone.replaceAll(' ','').replaceAll('-','');

			await loginUser(userPhone2, userCode)
		} catch (e) {
			setError(e)
		}
	}

	return (
		<>
			<LoginView
				error={error}
				userPhone={userPhone}
				userCode={userCode}
				regulations={regulations}
				onUserPhoneChange={onUserPhoneChange}
				onUserCodeChange={onUserCodeChange}
				onRegulationsChange={onRegulationsChange}
				onSubmit={onSubmit}
			/>
		</>
	);
};

export default LoginContainer;

import React, {useContext, useEffect, useState} from "react";
import {requestGetSurveyList} from "../../helpers/requests";
import {is, isNil} from "ramda";
import {SurveyContext} from "../contexts";
import {useHistory} from "react-router-dom";
import {PATH_PERSONAL_DATA, PATH_SURVEY} from "../../data/routes";
import SurveyListView from "../views/survey/SurveyListView";

export const defaultAppId = 1;
const availableAppsId = [1,4];

export const SurveyListContainer = () => {
	const [surveyId, setSurveyId] = useState(null)
	const [surveyList, setSurveyList] = useState([]);
	const [showList, setShowList] = useState(false);
	const {setSelectedSurveyId} = useContext(SurveyContext)
	const history = useHistory()

	useEffect(() => {
		getSurveyList()
	}, []);


	useEffect(() => {
		if (isNil(surveyId)) return;

		setSelectedSurveyId(surveyId);

		switch (surveyId) {
			case 1: {
				history.push(PATH_PERSONAL_DATA)
				break;
			}
			case 4: {
				history.push(PATH_SURVEY)
				break
			}
			default: {
				console.log('unknown app id')
			}
		}
	}, [surveyId])

	const getSurveyList = async () => {
		const response = await requestGetSurveyList()

		const fetchedSurveyList = response.data?.Applications;

		if (!is(Array, fetchedSurveyList)) {
			return;
		}

		const parsedSurveyList = fetchedSurveyList.map(app => {
			return {
				id: parseInt(app.Id),
				name: app.Name
			}
		})

		//remove application that we don't support now.
		const filteredSurveysId = parsedSurveyList.filter(application => {
			const surveyIdInt = parseInt(application.id)

			if (isNaN(surveyIdInt)) return false;

			return availableAppsId.includes(surveyIdInt)
		})

		switch (filteredSurveysId.length) {
			case 0: {
				setSurveyId(defaultAppId)

				return;
			}
			case 1: {
				setSurveyId(filteredSurveysId[0].id)

				return;
			}
			default: {
				setShowList(true);
				setSurveyList(filteredSurveysId)
			}
		}

	}

	const canShowList = showList && surveyList.length > 1;

	return (
		<SurveyListView
			surveyList={surveyList}
			canShowList={canShowList}
			setSurveyId={setSurveyId}
		/>
	)
}

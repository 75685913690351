import React from 'react';
import SurveyContextContainer from "./SurveyContextContainer";
import UserDataContextContainer from "./UserDataContextContainer";
import TokenContextContainer from "./TokenContextContainer";

const ContextsContainer = (props) => {
	return (
		<>
			<TokenContextContainer>
				<SurveyContextContainer>
					<UserDataContextContainer>
						{props.children}
					</UserDataContextContainer>
				</SurveyContextContainer>
			</TokenContextContainer>
		</>
	);
};

export default ContextsContainer;

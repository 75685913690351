import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../Button";

const propTypes = {
	isDisabled: PropTypes.bool,
	isHidden: PropTypes.bool,
	onClick: PropTypes.func,
};

const defaultProps = {};

const SurveyQuestionBtnFinish = (props) => {
	const {isDisabled, isHidden, onClick} = props;

	return (
		<>
			<Button
				disabled={isDisabled}
				hidden={isHidden}
				name="survey_finish"
				onClick={onClick}
			>
				Zakończ
			</Button>
		</>
	);
};

SurveyQuestionBtnFinish.propTypes = propTypes;
SurveyQuestionBtnFinish.defaultProps = defaultProps;

export default SurveyQuestionBtnFinish;

export default {
	login: {
		formTitleText: 'Zaloguj się',
		fieldPhonePlaceholder: 'Numer Telefonu',
		fieldUserCodePlaceholder: 'Kod użytkownika',
		formSubmitText: 'Zaloguj się',
		errors: {
			emptyFields: 'Proszę uzupełnić pola numer telefonu, kod użytkownika oraz zaakceptować regulamin',
			wrongData: 'Niepoprawny numer telefonu lub kod użytkownika',
			responseTokenError: 'Wystąpił problem z zalogowaniem użytkownika'
		}
	},
	surveyList: {
		chooseSurvey: 'Wybierz ankietę',
		logout: 'Wyloguj'
	},
	healthInfo: {
		formTitleText: 'Informacje o stanie zdrowia',
		formFieldDiseaseLabel: 'Choroby towarzyszące',
		formSubmitText: 'Zapisz',
	},
	survey: {
		errors: {
			apiError: 'Wystąpił problem podczas przesyłania wyniku'
		}
	},
	surveyFinished: {
		askQuestionButtonText: 'Formularz kontaktu z konsultantem medycznym',
		endSessionButtonText: 'Zakończ'
	},
	askQuestion: {
		formTitleText: 'Pytanie do konsultanta medycznego',
		formFieldQuestionLabel: 'Treść pytania',
		formFieldEmailLabel: 'E-mail',
		formSubmitText: 'Wyślij pytanie',
		errors: {
			sendQuestionError: 'Wystąpił problem z wysłaniem pytania',
			sendEmailError: 'Wystąpił problem z zapisaniem emaila'
		}
	}
}

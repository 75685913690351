import React from 'react';
import PropTypes from 'prop-types';
import classNames from "classnames";
import SurveyNavigation from "./SurveyNavigation";
import SurveyQuestionAnswers from "./SurveyQuestionAnswers";
import ErrorMessage from "../../ErrorMessage";

const propTypes = {
	selectedQuestion: PropTypes.number,
	questions: PropTypes.array,
	answers: PropTypes.object,
	isFormSubmitting: PropTypes.bool,
	error: PropTypes.string,
	selectAnswer: PropTypes.func,
	prevQuestion: PropTypes.func,
	nextQuestion: PropTypes.func,
	finishSurvey: PropTypes.func,
};

const defaultProps = {};

const SurveyView = (props) => {
	const {selectedQuestion, questions, answers, isFormSubmitting, error, selectAnswer, prevQuestion, nextQuestion, finishSurvey} = props;

	return (
		<>
			<div className="questionnaire">

				<div className="questionnaire__question__list">
					{questions.map((question, questionIndex) => {
						const isActive = questionIndex === selectedQuestion;

						return (
							<div key={questionIndex}
							     className={classNames('questionnaire__question__item', {'active': isActive})}
							>
								<div className="questionnaire__question__item__name box box-light">
									{question.label}
								</div>

								<div className="questionnaire__answers__list">
									<SurveyQuestionAnswers
										selectAnswer={selectAnswer}
										question={question}
										answers={answers}
									/>
								</div>
							</div>
						)
					})}

				</div>

				<SurveyNavigation
					selectedQuestion={selectedQuestion}
					questions={questions}
					answers={answers}
					isFormSubmitting={isFormSubmitting}
					prevQuestion={prevQuestion}
					nextQuestion={nextQuestion}
					finishSurvey={finishSurvey}
				/>

				<ErrorMessage errorMessage={error}/>
			</div>
		</>
	);
};

SurveyView.propTypes = propTypes;
SurveyView.defaultProps = defaultProps;

export default SurveyView;

import React from 'react';
import EndSessionButton from "../../EndSessionButton";
import logo_small from "../../../assets/img/logo_small.svg";
import enterna from "../../../assets/img/enterna.svg";

const propTypes = {};

const defaultProps = {};

const QuestionSentView = () => {

	return (
		<>
			<section className="screen">
				<div className="container container-custom">
					<div className="mb-5">
						<img src={enterna} className="logo-enterna img-fluid" alt=""/>
					</div>
					<div className="text-center">
						<h3>Dziękujemy</h3>
						<p>
							za przesłanie pytania do konsultanta medycznego, odpowiedź zostanie przygotowana i przesłana
							tak szybko jak będzie to możliwe.
						</p>

						<h4 className="text-custom-primary">WAŻNE!</h4>
						<p className="text-custom-primary">
							W przypadku zaostrzenia objawów sugerujemy pilny kontakt z lekarzem rodzinnym, lekarzem
							konsultującym/dyżurnym najbliższego Oddziału Obserwacyjno-Zakaźnego lub odpowiednią dla
							miejsca zamieszkania Stacją Sanitarno-Epidemiologiczną.
						</p>
						<p className="text-custom-primary">
							Jeżeli jest potrzeba natychmiastowej pomocy w sytuacji zagrożenia życia, należy zadzwonić na
							jeden z dwóch numerów ratunkowych: 999 lub 112.
						</p>
					</div>

					<div className="my-3 text-center">
						<EndSessionButton
							className={"btn-block"}
						/>
					</div>

					<p className="text-center">
						<img src={logo_small} alt="" className="img-fluid logo-small"/>
					</p>
				</div>
			</section>
		</>
	);
};

QuestionSentView.propTypes = propTypes;
QuestionSentView.defaultProps = defaultProps;

export default QuestionSentView;

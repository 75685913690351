import React, {useContext, useEffect, useState} from 'react';
import {TokenContext, UserDataContext} from "../contexts";
import {requestGetUserEmail} from "../../helpers/requests";

const UserDataContextContainer = (props) => {
	const [userEmail, setUserEmail] = useState("");
	const {token} = useContext(TokenContext)

	//TODO: fetch e-mail when token is set
	useEffect(() => {
		if (!token) return

		getUserEmailFromApi()
	}, [token])

	const getUserEmailFromApi = async () => {
		try {
			const response = await requestGetUserEmail()

			if (response?.data?.Email) {
				setUserEmail(response.data.Email)
			}

		} catch (e) {
			console.log(e)
		}
	}

	return (
		<>
			<UserDataContext.Provider value={{
				userEmail,
			}}>
				{props.children}
			</UserDataContext.Provider>
		</>
	);
};

export default UserDataContextContainer;

import {useContext, useEffect, useState} from "react";
import {surveyList, surveyQuestionTypeCheckbox} from "../data/surveyQuestions";
import SurveyContext from "../components/contexts/SurveyContext";
import {isDev} from "../helpers/functions";
import {defaultAppId} from "../components/containers/SurveyListContainer";

export const useSurveyData = () => {
	const {selectedSurveyId, surveyCompletedInPast} = useContext(SurveyContext)
	const [selectedQuestion, setSelectedQuestion] = useState(0);
	const [answers, setAnswers] = useState({});
	const [questions, setQuestions] = useState([]);

	//run when hook is mounted and check and filter questions
	useEffect(() => {
		isDev && console.log(surveyCompletedInPast, selectedSurveyId)

		let survey = surveyList[selectedSurveyId];

		if (!survey) {
			survey = surveyList[defaultAppId];
		}

		setDefaultValueToAnswers(survey);

		//filter all survey questions only when user fill this survey FIRST TIME
		if (!surveyCompletedInPast) {

			//find question with {showWhenSurveyCompletedBefore: true}, remove and set defaultValue
			survey = survey.filter(question => {
				if (question.showWhenSurveyCompletedBefore) {
					//fill this question with this key by defaultValue -> 0
					selectAnswer(question.key, 0)

					return false
				}

				return true;
			});

		}

		setQuestions(survey);

	}, [surveyCompletedInPast]);

	const setDefaultValueToAnswers = (survey) => {
		survey.forEach(question => {
			if (question.type === surveyQuestionTypeCheckbox) {
				question.answers.forEach(checkbox => {
					selectAnswer(checkbox.key, checkbox.initialValue)
				})
			} else {
				selectAnswer(question.key, question.initialValue);
			}
		});
	}

	//you can select any question by answerKey, not a selectedQuestion
	const selectAnswer = (answerKey, answerIndex) => {
		setAnswers(answers => {
			const updatedAnswers = {...answers};
			updatedAnswers[answerKey] = answerIndex;

			return updatedAnswers;
		});
	}

	const prevQuestion = () => {
		setSelectedQuestion(selectedQuestion => {
			let updatedSelectedQuestion = selectedQuestion;

			if (updatedSelectedQuestion === 0) return updatedSelectedQuestion;

			return --updatedSelectedQuestion;
		});
	}

	const nextQuestion = () => {
		setSelectedQuestion(selectedQuestion => {
			let updatedSelectedQuestion = selectedQuestion;

			if (updatedSelectedQuestion === questions.length - 1) return updatedSelectedQuestion;

			return ++updatedSelectedQuestion;
		});
	}

	return {
		selectedQuestion,
		questions,
		answers,
		selectAnswer,
		prevQuestion,
		nextQuestion,
	}
}

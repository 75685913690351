export const healthInfoQuestions = [
	{
		name: "ChorobaWiencowa",
		label: "Choroba wieńcowa",
		initialValue: 0,
	},
	{
		name: "NiewydolnoscSerca",
		label: "Niewydolność serca",
		initialValue: 0,
	},
	{
		name: "NadcisnienieTetnicze",
		label: "Nadciśnienie tętnicze",
		initialValue: 0,
	},
	{
		name: "AstmaPOChP",
		label: "Przewklekłe choroby płuc (Astma, POChP)",
		initialValue: 0,
	},
	{
		name: "PrzewleklaChorobaNerek",
		label: "Przewklekła choroba nerek",
		initialValue: 0,
	},
	{
		name: "ZadnaZPowyzszych",
		label: "Żadna z powyższych",
		initialValue: 0,
		resetOthers: true,
	}
];

export const convertPositionData = (position) => {
	return {
		gpsLat: position.coords.latitude,
		gpsLng: position.coords.longitude,
		gpsPre: position.coords.accuracy,
	}
};
export const getError = (error) => {
	let message = '';

	switch (error.code) {
		case error.PERMISSION_DENIED:
			message = "Nie zezwolono na pobranie lokalizacji";
			break;
		case error.POSITION_UNAVAILABLE:
			message = "Informacje o lokalizacji są niedostępne";
			break;
		case error.TIMEOUT:
			message = "Pobieranie lokalizacji trwało zbyt długo";
			break;
		case error.UNKNOWN_ERROR:
		default:
			message = "Wystąpił inny błąd w lokalizacją";
			break;
	}

	return message;
};
export const getCurrentGeoPosition = async () => {
	if (navigator.geolocation) {
		return new Promise((resolve, reject) => {
			navigator.geolocation.getCurrentPosition((position) => {
				resolve(convertPositionData(position))
			}, (error) => {
				reject(getError(error));
			}, {timeout: 5000});
		});
	} else {
		const message = 'Geolocation is not supported by this browser.';

		throw message;
	}
};

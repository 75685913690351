import React from 'react';
import enterna from "../../../assets/img/enterna.svg";
import {Link} from "react-router-dom";
import {PATH_ASK_QUESTIONS} from "../../../data/routes";
import logo_small from "../../../assets/img/logo_small.svg";
import appTexts from "../../../data/appTexts";
import EndSessionButton from "../../EndSessionButton";

const SurveySentPostCovidView = () => {

	return (
		<>
			<section className="screen">
				<div className="container container-custom">
					<div className="mb-5">
						<img src={enterna} className="logo-enterna img-fluid" alt=""/>
					</div>
					<div className="text-center">
						<div className="mb-3">
							<h2 className="text-custom-primary mb-0">
								Dziękujemy
							</h2>
							<h4 className="text-custom-primary">
								za wypełnienie ankiety samokontroli.
							</h4>
						</div>
						<p>
							Wprowadzone dane zostały wysłane do centrum telemonitoringu. Zostaną przeanalizowane przez lekarza specjalistę. <b>W razie potrzeby wskazana zostanie porada specjalistyczna z zakresu występujących, dominujących objawów.</b>
						</p>
						<p>
							<b>
								UWAGA: W sytuacjach bezpośredniego zagrożenia życia, należy wezwać karetkę pogotowia ratunkowego.
								Aby wezwać pogotowie ratunkowe, należy zadzwonić:
							</b>
						</p>
						<p>
							z telefonu stacjonarnego na numer 999, <br/>
							z telefonu komórkowego na numer 999 lub 112.
						</p>
						<p>
							<EndSessionButton/>
						</p>
					</div>
					<p className="text-center">
						<img src={logo_small} alt="" className="img-fluid logo-small"/>
					</p>
				</div>
			</section>
		</>
	);
};

export default SurveySentPostCovidView;

import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../Button";

const propTypes = {
	isDisabled: PropTypes.bool,
	onClick: PropTypes.func,
};

const defaultProps = {};

const SurveyQuestionBtnPrev = (props) => {
	const {isDisabled, onClick} = props;

	return (
		<>
			<Button
				color={"btn-custom-white"}
				disabled={isDisabled}
				onClick={onClick}
				name="survey_prev"
				className={"btn-chevron"}
			>
				<i className="icon icon-chevron-left"/>
			</Button>
		</>
	);
};

SurveyQuestionBtnPrev.propTypes = propTypes;
SurveyQuestionBtnPrev.defaultProps = defaultProps;

export default SurveyQuestionBtnPrev;

import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	minLabel: PropTypes.string,
	maxLabel: PropTypes.string,
	value: PropTypes.number,
	onChange: PropTypes.func,
	renderValue: PropTypes.func
};

const defaultProps = {
	renderValue: (value) => value,
};

const FormRange = (props) => {
	const {min = 0, max = 100, minLabel, maxLabel, value, onChange, renderValue} = props;

	return (
		<>
			<h3 className="text-center">{renderValue(value)}</h3>
			<input
				type="range"
				className="custom-range"
				min={min}
				max={max}
				step={1}
				value={value}
				onChange={onChange}
			/>
			{minLabel && maxLabel && (
				<div className="d-flex justify-content-between mt-1">
					<div>{minLabel}</div>
					<div>{maxLabel}</div>
				</div>
			)}
		</>
	);
};

FormRange.propTypes = propTypes;
FormRange.defaultProps = defaultProps;

export default FormRange;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
	color: PropTypes.string,
	disabled: PropTypes.bool,
	hidden: PropTypes.bool,
	type: PropTypes.oneOf(['button', 'submit']),
	className: PropTypes.string,
	name: PropTypes.string,
	onClick: PropTypes.func,
};

const defaultProps = {
	type: 'button',
	color: 'btn-custom-primary',
};

const Button = (props) => {
	const {type, color, disabled, hidden, className,name, children, onClick} = props;

	return (
		<>
			<button
				type={type}
				disabled={disabled}
				hidden={hidden}
				className={classNames("btn btn-custom", color, className)}
				name={name}
				onClick={onClick}
			>
				{children}
			</button>
		</>
	);
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;

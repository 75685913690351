export const API_PREFIX = 'https://api.sygmo.pl:2100/tms/posluga/covid/CovidDBService/';

const storagePrefix='sygmo_';

export const storageUpdateAvailableKey = `${storagePrefix}updateAvailable`;
export const storageUpdateAvailableValue = '1';
export const storageTokenKey = `${storagePrefix}token`;
export const storageUserDataKey = `${storagePrefix}userData`;

export const apiUserId ='OsobaId';
export const apiUserData = 'Personalia';

import React, {useContext} from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {PATH_SURVEYS_LIST, PATH_ASK_QUESTIONS, PATH_LOGIN, PATH_PERSONAL_DATA, PATH_SURVEY} from "../data/routes";
import LoginScreen from "../screens/LoginScreen";
import HealthInfoScreen from "../screens/HealthInfoScreen";
import TokenContext from "./contexts/TokenContext";
import AskQuestionScreen from "../screens/AskQuestionScreen";
import SurveyScreen from "../screens/SurveyScreen";
import SurveyListScreen from "../screens/SurveyListScreen";

const propTypes = {};

const defaultProps = {};

const Routing = (props) => {
	const {token} = useContext(TokenContext);

	return (
		<>
			<Router>
				<Switch>
					{token ? (
						<>
							<Route path={PATH_SURVEYS_LIST}>
								<SurveyListScreen/>
							</Route>

							<Route path={PATH_PERSONAL_DATA}>
								<HealthInfoScreen/>
							</Route>

							<Route path={PATH_SURVEY}>
								<SurveyScreen/>
							</Route>

							<Route path={PATH_ASK_QUESTIONS}>
								<AskQuestionScreen/>
							</Route>

							<Redirect to={PATH_SURVEYS_LIST}/>
						</>
					) : (
						<>
							<Route path={PATH_LOGIN}>
								<LoginScreen/>
							</Route>
							<Redirect to={PATH_LOGIN}/>
						</>
					)}
				</Switch>
			</Router>
		</>
	);
};

Routing.propTypes = propTypes;
Routing.defaultProps = defaultProps;

export default Routing;

import React, {useContext, useEffect, useState} from 'react'
import UserDataContext from "../contexts/UserDataContext"
import {is} from "ramda"
import AskQuestionView from "../views/ask_question/AskQuestionView"
import QuestionSentView from "../views/ask_question/QuestionSentView"
import {isEmptyOrHasWhitespaces, isValidEmail} from "../../helpers/functions";
import {requestSendQuestion, requestSetUserEmail} from "../../helpers/requests";
import appTexts from "../../data/appTexts";

const AskQuestionContainer = () => {
	const [question, setQuestion] = useState('')
	const [email, setEmail] = useState("")
	const [error, setError] = useState('')
	const [questionSent, setQuestionSent] = useState(false)

	const {userEmail} = useContext(UserDataContext)

	useEffect(() => {
		if (!is(String, userEmail)) return

		setEmail(userEmail)
	}, [userEmail])

	const onQuestionChange = (event) => {
		setQuestion(event.target.value)
	}

	const onEmailChange = (event) => {
		setEmail(event.target.value)
	}

	const onSubmit = async (event) => {
		event.preventDefault()

		setError('')

		if (userEmail !== email) {
			try {
				const response = await requestSetUserEmail(email)

				if (response.data.value !== 'Ok') {
					setUserEmailError()
					return
				}
			} catch (e) {
				console.log(e)

				setUserEmailError()
				return
			}
		}

		try {
			const response = await requestSendQuestion(question)

			if (response.data.value !== 'Ok') {
				sendQuestionError()
				return
			}
		} catch (e) {
			console.log(e)

			sendQuestionError()
			return
		}

		setQuestionSent(true)
	}

	const sendQuestionError = () => {
		setError(appTexts.askQuestion.errors.sendQuestionError)
	}

	const setUserEmailError = () => {
		setError(appTexts.askQuestion.errors.sendEmailError)
	}

	if (questionSent) return (
		<QuestionSentView/>
	)

	const submitButtonDisabled = isEmptyOrHasWhitespaces(question) || isEmptyOrHasWhitespaces(email) || !isValidEmail(email);

	return (
		<>
			<AskQuestionView
				question={question}
				email={email}
				error={error}
				submitButtonDisabled={submitButtonDisabled}
				onQuestionChange={onQuestionChange}
				onEmailChange={onEmailChange}
				onSubmit={onSubmit}
			/>
		</>
	)
}

export default AskQuestionContainer

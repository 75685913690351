export const surveyQuestionTypeRadio = 'radio';
export const surveyQuestionTypeCheckbox = 'checkbox';
export const surveyQuestionTypeTextarea = 'textarea';
export const surveyQuestionTypePercent = 'percent';
export const surveyQuestionTypeRange = 'range';

export const surveyList = {
	1: [
			{
				key: "0",
				type: surveyQuestionTypeRadio,
				initialValue: null,
				required: true,
				label: "Gorączka",
				answers: [
					{
						label: "nie, temp. poniżej 37.4°C",
					},
					{
						label: "nie, temp. 37.5 – 37.9°C",
					},
					{
						label: "tak, temp. 38.0 – 38.9°C"
					},
					{
						label: "tak, temp. 39.0 – 39.9°C"
					},
					{
						label: "tak, temp. 40°C lub więcej"
					},
				]
			},
			{
				key: "1",
				type: surveyQuestionTypeRadio,
				initialValue: null,
				required: true,
				label: "Ból gardła",
				answers: [
					{
						label: "brak"
					},
					{
						label: "bardzo słaby"
					},
					{
						label: "słaby"
					},
					{
						label: "umiarkowany"
					},
					{
						label: "dość silny"
					},
					{
						label: "bardzo silny"
					},
				]
			},
			{
				key: "2",
				type: surveyQuestionTypeRadio,
				initialValue: null,
				required: true,
				label: "Ból głowy",
				answers: [
					{
						label: "brak"
					},
					{
						label: "bardzo słaby"
					},
					{
						label: "słaby"
					},
					{
						label: "umiarkowany"
					},
					{
						label: "dość silny"
					},
					{
						label: "bardzo silny"
					},
				]
			},
			{
				key: "3",
				type: surveyQuestionTypeRadio,
				initialValue: null,
				required: true,
				label: "Ból mięśni",
				answers: [
					{
						label: "brak"
					},
					{
						label: "bardzo słaby"
					},
					{
						label: "słaby"
					},
					{
						label: "umiarkowany"
					},
					{
						label: "dość silny"
					},
					{
						label: "bardzo silny"
					},
				]
			},
			{
				key: "4",
				type: surveyQuestionTypeRadio,
				initialValue: null,
				required: true,
				label: "Uczucie ogólnego silnego zmęczenia, rozbicia",
				answers: [
					{
						label: "brak"
					},
					{
						label: "bardzo słabe"
					},
					{
						label: "słabe"
					},
					{
						label: "umiarkowane"
					},
					{
						label: "dość silne"
					},
					{
						label: "bardzo silne"
					},
				]
			},
			{
				key: "5",
				type: surveyQuestionTypeRadio,
				initialValue: null,
				required: true,
				label: "Duszność bądź duża trudność w oddychaniu",
				answers: [
					{
						label: "brak"
					},
					{
						label: "bardzo słaba"
					},
					{
						label: "słaba"
					},
					{
						label: "umiarkowana"
					},
					{
						label: "dość silna"
					},
					{
						label: "bardzo silna"
					},
				]
			},
			{
				key: "6",
				type: surveyQuestionTypeRadio,
				initialValue: null,
				required: true,
				label: "Kaszel",
				answers: [
					{
						label: "brak"
					},
					{
						label: "bardzo słaby"
					},
					{
						label: "słaby"
					},
					{
						label: "umiarkowany"
					},
					{
						label: "dość silny"
					},
					{
						label: "bardzo silny"
					},
				]
			},
			{
				key: "13",
				type: surveyQuestionTypeRadio,
				initialValue: null,
				required: true,
				label: "Zaburzenia węchu lub smaku",
				answers: [
					{
						label: "brak"
					},
					{
						label: "bardzo słabe"
					},
					{
						label: "słabe"
					},
					{
						label: "umiarkowane"
					},
					{
						label: "dość silne"
					},
					{
						label: "bardzo silne"
					}
				]
			},
			{
				key: "15",
				type: surveyQuestionTypeRange,
				required: true,
				label: "SpO2 w %",
				initialValue: 0,
				hint: "Jeżeli brak możliwości pomiaru, prosimy o przejście do kolejnego pytania",
			},
			{
				key: "7",
				type: surveyQuestionTypeTextarea,
				initialValue: "",
				label: "Inne dolegliwości",
				maxLength: 500,
				hint: "Jeśli brak, prosimy o przejście do kolejnego pytania",
			},
			{
				key: "8",
				type: surveyQuestionTypeRadio,
				initialValue: null,
				required: true,
				label: "W porównaniu do poprzedniej oceny czujesz się",
				showWhenSurveyCompletedBefore: true,
				answers: [
					{
						label: "znacznie lepiej"
					},
					{
						label: "nieco lepiej"
					},
					{
						label: "tak samo"
					},
					{
						label: "nieco gorzej"
					},
					{
						label: "znacznie gorzej"
					},
				]
			},
			{
				key: "9-12,14",
				type: surveyQuestionTypeCheckbox,
				label: "Wywiad epidemiologiczny",
				hint: "Jeśli żadna z powyższych odpowiedzi prosimy o przejście do ekranu kończącego ankietę samokontroli",
				answers: [
					{
						key: "9",
						label: "Trwająca kwarantanna",
						initialValue: 0,
					},
					{
						key: "10",
						label: "Kontakt z osobą objętą kwarantanną?",
						initialValue: 0,
					},
					{
						key: "11",
						label: "Bezpośredni kontakt z osobą zakażoną koronawirusem lub kontakt z tą osobą w odległości mniej niż 1,5 metra przez ponad 15 minut",
						initialValue: 0,
					},
					/*{
						key: "12",
						label: "Pobyt w szpitalu w ciągu ostatnich 2 tygodni?",
						initialValue: 0,
					},*/
					{
						key: "14",
						label: "SARS-CoV-2 wynik pozytywny",
						initialValue: 0,
					},
					{
						key: "16",
						label: "Szczepienie przeciw COVID-19 wykonane w ciągu ostatnich 270 dni",
						initialValue: 0,
					}
				]
			},
		],
	4: [
		{
			key: "0",
			type: surveyQuestionTypeRadio,
			required: true,
			initialValue: null,
			label: "Męczliwość",
			answers: [
				{
					label: "nie, nie występuje",
				},
				{
					label: "tak, sporadycznie oraz niewielka"
				},
				{
					label: "tak, nieczęsto i niezbyt dokuczliwa"
				},
				{
					label: "tak, często, utrudniająca codzienne funkcjonowanie"
				},
				{
					label: "tak, bardzo często, znacznie utrudniająca codzienne funkcjonowanie"
				}
			]
		},
		{
			key: "1",
			type: surveyQuestionTypeRadio,
			required: true,
			initialValue: null,
			label: "Duszność",
			answers: [
				{
					label: 'nie, nie występuje'
				},
				{
					label: "tak, niewielka - tylko przy intensywnym wysiłku (np. po przejściu powyżej 500 m)"
				},
				{
					label: "tak, umiarkowana – przy umiarkowanym wysiłku (np. po przejściu powyżej 200 m)"
				},
				{
					label: "tak, silna - nawet przy niewielkim wysiłku (po przejściu kilku - kilkunastu metrów)"
				},
				{
					label: "tak, nawet podczas spoczynku"
				}
			]
		},
		{
			key: "2",
			type: surveyQuestionTypeRadio,
			required: true,
			initialValue: null,
			label: "Kaszel",
			answers: [
				{
					label: "brak"
				},
				{
					label: "tak, niezbyt często i łagodny"
				},
				{
					label: "tak, często, zazwyczaj ciężki i uporczywy, utrudniający codzienne funkcjonowanie"
				}
			]
		},
		{
			key: "3",
			type: surveyQuestionTypeRadio,
			required: true,
			initialValue: null,
			label: "Temperatura ciała w ostatnich 3 dniach",
			answers: [
				{
					label: "nie większa niż 36,9 stopni"
				},
				{
					label: "pomiędzy 37 a 37,9 stopni"
				},
				{
					label: "pomiędzy 38 a 38,9 stopni"
				},
				{
					label: "powyżej 38,9 stopnia"
				}
			]
		},
		{
			key: "4",
			type: surveyQuestionTypeRadio,
			required: true,
			initialValue: null,
			label: "Bóle w klatce piersiowej",
			answers: [
				{
					label: "nie, nie występują"
				},
				{
					label: "tak, niewielkie – tylko przy intensywnym wysiłku (np. po przejściu powyżej 500 m)"
				},
				{
					label: "tak, umiarkowane - przy umiarkowanym wysiłku (np. po przejściu powyżej 200 m)"
				},
				{
					label: "tak, silne - nawet przy niewielkim wysiłku (po przejściu kilku – kilkunastu metrów)"
				},
				{
					label: "tak, nawet podczas spoczynku"
				}
			]
		},
		{
			key: "5",
			type: surveyQuestionTypeRadio,
			required: true,
			initialValue: null,
			label: "Kołatanie serca",
			answers: [
				{
					label: "nie, nie występuje"
				},
				{
					label: "tak, ale było już wcześniej, nie nasiliło się w ostatnim czasie"
				},
				{
					label: "tak, było już wcześniej, ale uległo nasileniu w ostatnim czasie"
				},
				{
					label: "tak, pojawiło się po przechorowaniu COVID-19"
				}
			]
		},
		{
			key: "6",
			type: surveyQuestionTypeRadio,
			required: true,
			initialValue: null,
			label: "Obrzęki",
			answers: [
				{
					label: "nie, nie występują"
				},
				{
					label: "tak, ale niewielkie lub tylko w godzinach wieczornych"
				},
				{
					label: "tak, nie przekraczają połowy łydek, ale zazwyczaj utrzymują się cały dzień"
				},
				{
					label: "tak, są duże i sięgają do kolan"
				},
				{
					label: "tak, są duże i sięgają powyżej kolan lub występuje zwiększenie obwodu brzucha i szybki wzrost masy ciała (2-3 kg w ciągu 2 dni)"
				}
			]
		},
		{
			key: "8-12",
			type: surveyQuestionTypeCheckbox,
			label: "Dolegliwości ze strony układu nerwowego",
			answers: [
				{
					key: "8",
					label: "bóle i zawroty głowy utrudniające codzienne funkcjonowanie",
					initialValue: 0,
				},
				{
					key: "9",
					label: "problemy z koncentracją, wysławianiem się oraz pamięcią utrudniające codzienne funkcjonowanie",
					initialValue: 0,
				},
				{
					key: "10",
					label: "zaburzenia czucia, zwłaszcza rąk, nóg, twarzy (drętwienia, kłucia, „cierpnięcia”), problemy z swobodnym wykonywaniem ruchów (np. unoszenie rąk, nóg); osłabienie siły mięśniowej",
					initialValue: 0,
				},
				{
					key: "11",
					label: "zaburzenia słuchu i wzroku (niedowidzenie, niedosłuch, zaburzenia równowagi)",
					initialValue: 0,
				},
				{
					key: "12",
					label: "zaburzenia węchu i smaku trwające więcej niż 4 tygodnie po wyzdrowieniu",
					initialValue: 0,
				},
			]
		},
		{
			key: "13-14",
			type: surveyQuestionTypeCheckbox,
			label: "Zaburzenia nastroju",
			answers: [
				{
					key: "13",
					label: "zły nastrój (niepokój, depresja) utrudniający codzienne funkcjonowanie",
					initialValue: 0,
				},
				{
					key: "14",
					label: "myśli samobójcze",
					initialValue: 0,
				},
			]
		},
		{
			key: "7,15,16",
			type: surveyQuestionTypeCheckbox,
			label: "Pozostałe objawy",
			answers: [
				{
					key: "7",
					label: "zasłabnięcie lub omdlenie lub utrata przytomności",
					initialValue: 0,
				},
				{
					key: "15",
					label: "uporczywa biegunka, czyli co najmniej 6 luźnych stolców jednego dnia lub co najmniej po 3 luźne stolce w ciągu kolejnych 3 dni",
					initialValue: 0,
				},
				{
					key: "16",
					label: "przedłużające się krwawienia z ran, zmiany wybroczynowe („siniaki”), stolce smoliste bądź z domieszką świeżej krwi",
					initialValue: 0,
				},
			]
		},
		{
			key: "17",
			type: surveyQuestionTypeRange,
			rangeMin: 1,
			rangeMax: 10,
			minLabel: 'najgorzej',
			maxLabel: 'najlepiej',
			initialValue: 1,
			required: true,
			label: "Samopoczucie w dniu dzisiejszym"
		},
	],
};

import React from 'react';
import PropTypes from 'prop-types';
import {is} from "ramda";

const propTypes = {
	errorMessage: PropTypes.string,
};

const defaultProps = {};

const ErrorMessage = (props) => {
	const {errorMessage} = props;

	if (!errorMessage || !is(String, errorMessage)) return null;

	return (
		<>
			<div className="text-center text-danger my-3">
				{errorMessage}
			</div>
		</>
	);
};

ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;

export default ErrorMessage;

import {storageUpdateAvailableKey} from "../data/config";
import {isEmpty} from "ramda";
import axios from 'axios';

// return development if u using react app locally: http://localhost:3000
export const isDev = process.env.NODE_ENV === 'development';

// check if app was reloaded because of serviceWorker SKIP_WAITING action
export const isAppHaveUpdate = () => {
	const value = sessionStorage.getItem(storageUpdateAvailableKey);

	return value === '1';
}

// check if value is empty of has whitespaces like " ", "  "
export const isEmptyOrHasWhitespaces = (value) => {
	return isEmpty(value.trim())
}

//set token from login request to all future request as default
export const setTokenToAllUpcomingRequests = (token) => {
	if (!token) return

	axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}

// use it to validate email value
// https://emailregex.com/
export const isValidEmail = (email) => {
	if (!email) return false

	const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

	return new RegExp(emailRegExp).test(email)
}

import React from 'react';
import PropTypes from 'prop-types';
import Button from "../../Button";
import ErrorMessage from "../../ErrorMessage";
import appTexts from "../../../data/appTexts";
import {FormGroup, FormInput, FormLabel, FormTextarea} from "../../form";
import EndSessionButton from "../../EndSessionButton";

const propTypes = {
	question: PropTypes.string,
	email: PropTypes.string,
	error: PropTypes.string,
	submitButtonDisabled: PropTypes.bool,
	onQuestionChange: PropTypes.func,
	onEmailChange: PropTypes.func,
	onSubmit: PropTypes.func,
};

const defaultProps = {};

const AskQuestionView = (props) => {
	const {question, email, error, submitButtonDisabled, onQuestionChange, onEmailChange, onSubmit} = props;

	return (
		<>
			<section className="screen">
				<div className="container container-custom">
					<div className="box box-light">{appTexts.askQuestion.formTitleText}</div>

					<form>
						<FormGroup>
							<FormLabel>
								{appTexts.askQuestion.formFieldQuestionLabel}
							</FormLabel>
							<FormTextarea
								value={question}
								name={"question_content"}
								onChange={onQuestionChange}
							/>
						</FormGroup>

						<FormGroup>
							<FormLabel>
								{appTexts.askQuestion.formFieldEmailLabel}
							</FormLabel>
							<FormInput
								value={email}
								name={"question_email"}
								onChange={onEmailChange}
							/>
						</FormGroup>

						<Button
							type={"submit"}
							name={"question_submit"}
							disabled={submitButtonDisabled}
							onClick={onSubmit}
							className={"btn-block"}
						>
							{appTexts.askQuestion.formSubmitText}
						</Button>

						<ErrorMessage
							errorMessage={error}
						/>

						<div className="mt-3 text-center">
							<p>lub</p>
							<EndSessionButton
								className={"btn-block"}
							/>
						</div>
					</form>


				</div>
			</section>

		</>
	);
};

AskQuestionView.propTypes = propTypes;
AskQuestionView.defaultProps = defaultProps;

export default AskQuestionView;

import React from 'react';
import casus from "../assets/img/casus.svg";
import wim from "../assets/img/wim.png";

const LoginScreenFooter = () => {
	return (
		<>
			<div className="text-center">
				<p>
					Realizacja i prawa:
				</p>

				<p>
					<b>
						Grupa casusBTL, Poznań
					</b>
				</p>
				<p>
					<img src={casus} className="logo-casus" alt=""/>
				</p>

				<p>
					<b>
						Wojskowy Instytut Medyczny, Warszawa
					</b>
				</p>
				<p>
					<img src={wim} alt=""/>
				</p>
			</div>
		</>
	);
};

export default LoginScreenFooter;
